// require('dotenv').config()
import React from 'react'
import Refund from "../Refund";
import "./menu.css"
import { FaCog, FaInbox, FaEnvelope, FaUncharted, FaSearch, FaSignOutAlt, FaPuzzlePiece } from "react-icons/fa";
import { useState } from 'react';
import { useAuth } from '../../context/AuthProvider';
import { useHistory } from "react-router-dom"
// import { Button, Alert } from 'antd';

export default function Menu({ logic, page, description }){

    const { logout } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    // const [search, setSearch] = useState('');
    const history = useHistory()

    const handleLogout = async () => {
        try {
          setError("")
          setLoading(true)
          await logout()
          history.push("/login")
        } catch {
          setError("Failed to log out")
        }
        setLoading(false)
      }


    return (
        <>
            <div className='primary'>

                <div className='secondary'>
                    <div className='header'>
                        {/* <h2 className='title'>AICS</h2> */}
                        <img className='chirpish' src="chirpish.png" alt="Chirpish logo" width="40" height="40"></img>
                        {/* <div className='menu-account2'> */}
                            {/* <button onClick={() => handleLogout()} className="button2" loading={loading} type="primary"><img src="shrek.jpg" alt="Girl in a jacket" width="40" height="40"></img></button> */}
                        {/* </div> */}
                        {/* <FaSearch className='icon'/> */}
                    </div>
                    <br></br>
                    <div className='menu'>
                        <a href="https://app.mergelabs.co/" className={"Settings" === page ? 'b1' : 'b2' }><FaPuzzlePiece className={"Settings" === page ? 'icon' : 'icon2' }/></a>
                        <a href="https://app.mergelabs.co/a" className={"Analytics" === page ? 'b1' : 'b2' }><FaUncharted className={"Analytics" === page ? 'icon' : 'icon2' }/></a>
                    </div>
                    <div className='menu-account'>
                    <button onClick={() => handleLogout()} className="button2" loading={loading} type="primary"><FaSignOutAlt></FaSignOutAlt></button>
                    </div>
                </div>
            </div>

            <div className='logic'>
                <div className='header2'>
                    <h1 className='title'>{page}</h1>
                    <p className='par'>{description}</p>
                </div>
                <div className='logic-in-logic'>
                    {logic}
                </div>
            </div>
        </>
    )
}