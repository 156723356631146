import React, { useContext, useState, useEffect } from "react";
import { auth } from "../utils/firebase";
import firebase from "firebase/app";
import axios from "axios";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {

  // const API_URL =  `http://127.0.0.1:8080`;   
  const API_URL = `https://app.mergelabs.co`;   

  const [currentUser, setCurrentUser] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [brands, setBrands] = useState([])
  const [loading, setLoading] = useState(true);
  const [brandIdLoaded, setBrandIdLoaded] = useState(false);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function googleAuth() {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    return firebase.auth().signInWithPopup(provider);
  }

  function logout() {
    console.log("calling logout");
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  async function setSelectedBrand(brandId) {
    console.log("set selected brand")
    setBrandId(brandId);
    setBrandIdLoaded(true);

    const uid = currentUser ? currentUser.uid : null;

    if (uid) {
      const data = { uid, brandId: brandId };

      console.log("AuthProvider update brandId: ", data)

      try {
        const response = await axios.post('/customer/setBrand', data, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`,
            'X-Api-Key': process.env.REACT_APP_API_KEY, // Include the API key in the request headers
          }
        });
  
        console.log("Brand selected and saved successfully.", response.data);
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  }



  async function fetchCurrentBrand() {
    console.log("in fetchCurrentBrand: ,", currentUser)
    if (!currentUser || !currentUser.uid) {
      return null;
    }
  
    try {
      const idToken = await currentUser.getIdToken(); // Obtain the ID token
  
      const response = await axios.post(`${API_URL}/customer/getBrand`, { uid: currentUser.uid }, {
        headers: {
          Authorization: `Bearer ${idToken}`, // Include the ID token in the request headers
          'X-Api-Key': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      });
  
      console.log("Response from in fetchCurrentBrand: ", response);
      const { success, currentBrand } = response.data;
  
      if (success) {
        return currentBrand;
      } else {
        console.error("/getBrands was not successful.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching currentBrand:", error.message);
      return null;
    }
  }



  const removeDuplicateBrands = (brandsArray) => {
    const uniqueBrands = [];
    const uniqueIds = new Set();
  
    for (const obj of brandsArray){
      if (!uniqueIds.has(obj.id)) {
        uniqueBrands.push(obj);
        uniqueIds.add(obj.id);
      }

      setBrands(uniqueBrands);
    }
  }



  // useEffect(() => {
  //   if (currentUser && currentUser.uid) {
  //     axios.post(`${API_URL}/customer/getBrand`, { uid: currentUser.uid })
  //       .then(response => {
  //         const { success, currentBrand, brands } = response.data;
  //         if (success) {
  //           console.log(`/getBrands successful: currentBrand: ${currentBrand}, brands: ${JSON.stringify(brands)}`)
  //           console.log(`brands: ${JSON.stringify(brands)}`)
  //           removeDuplicateBrands(brands);
  //           setBrandId(currentBrand);
  //           // setBrands(brands)
  //         }
  //       })
  //       .catch(error => {
  //         console.error("Error fetching brandId:", error.message);
  //       })
  //       .finally(() => {
  //         setBrandIdLoaded(true);
  //       });
  //   }
  // }, [currentUser]);


  useEffect(() => {
    const fetchBrandData = async () => {
      try {
        if (currentUser && currentUser.uid) {
          const idToken = await currentUser.getIdToken(); // Obtain the ID token
  
          const response = await axios.post(`${API_URL}/customer/getBrand`, { uid: currentUser.uid }, {
            headers: {
              Authorization: `Bearer ${idToken}`, // Include the ID token in the request headers
              'X-Api-Key': process.env.REACT_APP_API_KEY // Include the API key in the request headers
            }
          });
  
          const { success, currentBrand, brands } = response.data;
  
          if (success) {
            // console.log(`/getBrands successful: currentBrand: ${currentBrand}, brands: ${JSON.stringify(brands)}`);
            // console.log(`brands: ${JSON.stringify(brands)}`);
            removeDuplicateBrands(brands);
            setBrandId(currentBrand);
          }
        }
      } catch (error) {
        console.error("Error fetching brandId:", error.message);
      } finally {
        setBrandIdLoaded(true);
      }
    };
  
    fetchBrandData();
  }, [currentUser]);


  // When something changes where a brand is created we want to change the brandId
  async function triggerBrand() {
    try {
      if (currentUser && currentUser.uid) {
        const idToken = await currentUser.getIdToken(); // Obtain the ID token
  
        const response = await axios.post(`${API_URL}/customer/getBrand`, { uid: currentUser.uid }, {
          headers: {
            Authorization: `Bearer ${idToken}`, // Include the ID token in the request headers
            'X-Api-Key': process.env.REACT_APP_API_KEY // Include the API key in the request headers
          }
        });
  
        const { success, currentBrand, brands } = response.data;
  
        if (success) {
          // console.log(`/getBrands successful: currentBrand: ${currentBrand}, brands: ${JSON.stringify(brands)}`);
          // console.log(`brands: ${JSON.stringify(brands)}`);
          removeDuplicateBrands(brands);
          setBrandId(currentBrand);
        }
      }
    } catch (error) {
      console.error("Error fetching brandId:", error.message);
    } finally {
      setBrandIdLoaded(true);
    }
  }
  


  // useEffect(() => {
  //   if (currentUser && currentUser.uid) {
  //     axios.post(`${API_URL}/customer/getBrand`, { uid: currentUser.uid })
  //       .then(response => {
  //         const { success, currentBrand, brands } = response.data;
  //         if (success) {
  //           console.log(`/getBrands successful: currentBrand: ${currentBrand}, brands: ${JSON.stringify(brands)}`)
  //           setBrandId(currentBrand);
  //           setBrands(brands)
  //         }
  //       })
  //       .catch(error => {
  //         console.error("Error fetching brandId:", error.message);
  //       })
  //       .finally(() => {
  //         setBrandIdLoaded(true);
  //       });
  //   }
  // }, [currentUser]);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setLoading(false);
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    brandId,
    brands,
    login,
    signup,
    logout,
    googleAuth,
    resetPassword,
    updateEmail,
    updatePassword,
    setSelectedBrand,
    brandIdLoaded,
    setBrandId,
    fetchCurrentBrand,
    triggerBrand
  };

  const shouldRenderBrandId = !loading && currentUser && brandIdLoaded;
  const shouldRenderNoBrandId = !loading && !currentUser;

  return (
    <AuthContext.Provider value={value}>
      {shouldRenderBrandId && children}
      {shouldRenderNoBrandId && children}
    </AuthContext.Provider>
  );
}



// const removeDuplicateBrands = (brandsArray) => {
//   const uniqueBrands = [];
//   const uniqueIds = new Set();
// ​
//   for (const obj of brandsArray){
//     if (!uniqueIds.has(obj.id)) {
//       uniqueBrands.push(obj);
//       uniqueIds.add(obj.id);
//   }
// ​
//   setBrands(uniqueBrands);
// }
// }
// ​
// ​
// useEffect(() => {
//   if (currentUser && currentUser.uid) {
//     axios.post(`${API_URL}/customer/getBrand`, { uid: currentUser.uid })
//       .then(response => {
//         const { success, currentBrand, brands } = response.data;
//         if (success) {
//           console.log(`/getBrands successful: currentBrand: ${currentBrand}, brands: ${JSON.stringify(brands)}`)
//           console.log(`brands: ${JSON.stringify(brands)}`)
//           removeDuplicateBrands(brands);
//           setBrandId(currentBrand);
//           // setBrands(brands)
//         }
//       })
//       .catch(error => {
//         console.error("Error fetching brandId:", error.message);
//       })
//       .finally(() => {
//         setBrandIdLoaded(true);
//       });
//   }
// }, [currentUser]);